import React from "react";
import { AiOutlineHome, AiOutlineInfoCircle, AiOutlineAppstore, AiOutlineTeam, AiOutlineAim, AiOutlinePhone } from "react-icons/ai";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaPhoneAlt } from "react-icons/fa";
import { FaPhone, FaEnvelope, FaGlobe } from 'react-icons/fa'; // Import icons
import { Box, Typography } from '@mui/material';
import { BiSolidPhoneCall } from 'react-icons/bi'; 
import logo from "../../assets/logo1.jpeg";
import ContactInfo from "../contact/ContactInfo";

const menu = [
  
  { arabic: "من نحن", english: "About Us", link: "/about", icon: <AiOutlineInfoCircle /> },
  { arabic: "الخدمات", english: "Services", link: "/logistic", icon: <AiOutlineAppstore /> },
  { arabic: "لوجستي", english: "Logistic", link: "/services", icon: <AiOutlineAppstore /> },
  { arabic: "قيمنا", english: "Our Values", link: "/our-values", icon: <AiOutlineTeam /> },
  { arabic: "أهدافنا", english: "Our Goals", link: "/goals", icon: <AiOutlineAim /> },
  { arabic: "اتصل بنا", english: "Contact Us", link: "/contact-us", icon: <AiOutlinePhone /> },
];

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-16">
      <div className="container mx-auto px-6 lg:px-12">
        {/* Footer Content Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          
          {/* Company Logo & Name Section */}
          <div className="flex flex-col items-center sm:items-start text-center sm:text-left">
            <img
              src={logo}
              alt="Company Logo"
              className="h-12 mb-4 bg-white"
            />
            <h2 className="text-2xl sm:text-3xl  text-white"  style={{ fontFamily: "Amiri, sans-serif" }}>
              ﺷﺮﻛﺔـ اﻟﻌﻄـﺎء اﻟﺤﺩـﻳﺚﻟﻠﺘﺸﻐﻴـﻞـ واﻟﺼﻴﺎﻧـﺔ واﻟﻤﻘﺎوﻻت
            </h2>
            <p className="text-gray-400 mt-2">Innovating for a Better Future</p>
            <a
              href="tel:+1234567890" 
              className="mt-6 bg-orange-500 text-white px-8 py-3 flex items-center space-x-2 hover:bg-orange-600 transition-all duration-300"
            >
              <FaPhoneAlt />
              <span>Call Us</span>
            </a>
          </div>
      
          {/* Links Section */}
          <div className="flex flex-col items-center sm:items-start space-y-4 md:space-y-6">
            <ul className="space-y-2">
              {menu.map((item, index) => (
                <li key={index}>
                  <a href={item.link} className="text-lg text-gray-300 hover:text-white transition-all duration-300 flex items-center space-x-2">
                    {item.icon}
                    <span>{item.english} ({item.arabic})</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {/* Social Media & Contact Us Section */}
          <div className="flex flex-col items-center sm:items-end space-y-4">
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2, paddingLeft: '20px' }}>
      
      {/* Company Logo */}
      {/* <Box sx={{ mb: 2 }}>
        <img src={logo} alt="Company Logo" style={{ width: '100px', height: 'auto' }} />
      </Box> */}

      {/* Phone Icon and Text */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2  ,  color: '#fff',}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: '100%',
            backgroundColor: '#f97316', // Orange-500 color
            color: '#fff', // White text for icons
          }}
        >
          <BiSolidPhoneCall />
        </Box>
        <Typography variant="body1" sx={{ color: '#fff', textAlign: 'left' }}>
          +054 504 3287
        </Typography>
      </Box>

      {/* Email Icon and Text */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: '100%',
            backgroundColor: '#f97316', // Orange-500 color
            color: '#fff', // White text for icons
          }}
        >
          <FaEnvelope />
        </Box>
        <Typography variant="body1" sx={{ color: '#fff', textAlign: 'left' }}>
        Info@amc-co.co
        </Typography>
      </Box>

      {/* Website Icon and Text */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 40,
            height: 40,
            borderRadius: '100%',
            backgroundColor: '#f97316', // Orange-500 color
            color: '#fff', // White text for icons
          }}
        >
          <FaGlobe />
        </Box>
        <Typography variant="body1" sx={{ color: '#fff', textAlign: 'left' }}>
          www.amc.co.co
        </Typography>
      </Box>

      {/* Address Details */}
      <Box sx={{ mt: 3, textAlign: 'left', paddingLeft: '10px' }}>
        <Typography variant="body1" sx={{ color: '#fff', fontWeight: 'bold' }}>
          Address:
        </Typography>
        <Typography variant="body2" sx={{ color: '#fff', marginTop: 1 }}>
          8138 Abban Al Bajli, 4112 Al Ferdous Dist., Jeddah, Saudi Arabia, PO-23818O
        </Typography>
      </Box>
    </Box>
            <div className="flex space-x-6 mt-6">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-600 p-4 rounded-full hover:bg-blue-500 transition-all duration-300"
              >
                <FaFacebookF className="text-white text-xl" />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-400 p-4 rounded-full hover:bg-blue-300 transition-all duration-300"
              >
                <FaTwitter className="text-white text-xl" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-pink-500 p-4 rounded-full hover:bg-pink-400 transition-all duration-300"
              >
                <FaInstagram className="text-white text-xl" />
              </a>
              <a
                href="https://linkedin.com"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-700 p-4 rounded-full hover:bg-blue-600 transition-all duration-300"
              >
                <FaLinkedinIn className="text-white text-xl" />
              </a>
            </div>
          </div>
        </div>

        {/* Footer Bottom Text */}
        <div className="text-center mt-8">
          <p className="text-gray-500 text-sm">&copy; 2024 Al-Ataa Modern Contracting. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
