import React from "react";
import { Box, Typography, Grid, Card, CardContent, Avatar, Button } from "@mui/material";
import integrityImage from "../../assets/ahemd.jpg";
import innovationImage from "../../assets/images/image-2.jpg";
import excellenceImage from "../../assets/images/image-3.jpg"
import teamworkImage from "../../assets/images/image-4.jpg";
import teamworkImage1 from "../../assets//images/image-5.jpg";
import logisticsImage from "../../assets/sahil.jpg";
import Hr from "../../assets/Hr .jpg";
import Majed from "../../assets/Majed.jpg"
import image2 from "../../assets/images/image-1.jpg"
import image1 from "../../assets/images/image-2.jpg"
const teamMembers = [
  {
    name: { en: "Halim", ar: "حليم" },
    position: { en: "CEO", ar: "المدير التنفيذي" },
    description: {
      en: "Leading the company with innovative strategies while ensuring steady growth and innovation.",
      ar: "قيادة الشركة باستراتيجيات مبتكرة مع ضمان النمو المستمر والابتكار.",
    },
    photo: teamworkImage1,
  }
  
,  
  
  {
    name: { en: "Sajidali", ar: "ساجد علي" },
    position: { en: "Deputy CEO", ar: "نائب المدير التنفيذي" },
    description: {
      en: "Developing the company’s vision in operations and strategy implementation.",
      ar: "تطوير رؤية الشركة في العمليات وتنفيذ الاستراتيجيات.",
    },
    // photo: image1,
  },
 
  {
    name: { en: "Majed", ar: "ماجد" },
    position: { en: "General Manager", ar: "المدير العام" },
    description: {
      en: "Efficiently managing operations while aligning workflows with strategic objectives.",
      ar: "إدارة العمليات بكفاءة مع مواءمة تدفقات العمل مع الأهداف الاستراتيجية.",
    }
,    
    photo: Majed,
  },
  {
    name: { en: "Mahmoud", ar: "محمود" },
    position: { en: "Human Resources", ar: "الموارد البشرية" },
    description: {
      en: "Managing HR functions and fostering a positive workplace environment.",
      ar: "إدارة وظائف الموارد البشرية وتعزيز بيئة عمل إيجابية.",
    },
    photo: Hr,
  },
  {
    name: { 
      en: "Ahmed", 
      ar: "أحمد" 
    },
    position: { 
      en: "Finance Manager", 
      ar: "مدير مالي" 
    },
  description: {
  en: "Overseeing financial operations and ensuring compliance with regulations.",
  ar: "الإشراف على العمليات المالية وضمان الامتثال للوائح."
},
    photo: integrityImage
  },
  {
    name: {
      en: "Sahil",
      ar: "ساهل"
    },
    position: { 
      en: "Logistic Department", 
      ar: "قسم الخدمات اللوجستية" 
    },
  description: {
  en: "Managing supply chains and coordinating logistics operations.",
  ar: "إدارة سلاسل التوريد وتنسيق العمليات اللوجستية."
},
    photo: logisticsImage
  },
  {
    name: { en: "Ansar", ar: "أنصار" },
    position: { en: "Caretaker", ar: "المسؤول" },
    description: {
      en: "Overseeing tasks and ensuring operational excellence across diverse services.",
      ar: "الإشراف على المهام وضمان التميز التشغيلي عبر الخدمات المتنوعة.",
    }
    ,
    photo: image2,
  },
  
  {
    name: { en: "Mohammed", ar: "محمد" },
    position: { en: "Shared Services Center", ar: "مركز الخدمات المشتركة" },
    description: {
      en: "Delivering essential services and ensuring operational efficiency.",
      ar: "تقديم الخدمات الأساسية وضمان الكفاءة التشغيلية.",
    },
    photo: teamworkImage,
  },
];



const Teampage = () => {
  return (
    <div>
    <Box
      sx={{
        padding: 4,
        background: "linear-gradient(145deg, #1f1c2c, #928dab)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Header */}
      <Typography
        variant="h3"
        sx={{
          fontWeight: "bold",
          background: '#F97316',
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          marginBottom: 3,
          textAlign: "center",
        }}
      >
        Meet Our Team | تعرف على فريقنا
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: "#fff", textAlign: "center", maxWidth: 600, marginBottom: 4 }}
      >
        A passionate group of professionals driving excellence and innovation. |
        مجموعة من المهنيين الشغوفين يقودون التميز والابتكار.
      </Typography>

      {/* Team Grid */}
      <Grid container spacing={4} justifyContent="center">
  {/* First Card */}
  <Grid 
    item 
    xs={12} 
    sm={6} 
    md={4} 
    lg={3}
  >
    <Card
      sx={{
        background: "linear-gradient(145deg, #e6e6e6, #ffffff)",
        boxShadow: "10px 10px 30px #bebebe, -10px -10px 30px #ffffff",
        borderRadius: "20px",
        padding: 3,
        textAlign: "center",
        "&:hover": {
          transform: "scale(1.05)",
          transition: "transform 0.3s ease",
        },
      }}
    >
      <Avatar
        alt={teamMembers[0].name.en}
        src={teamMembers[0].photo}
        sx={{
          width: 120,
          height: 120,
          margin: "auto",
          border: "5px solid #ffffff",
          boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
        }}
      />
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            marginBottom: 1,
            background: '#F97316',
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {teamMembers[0].name.en} | {teamMembers[0].name.ar}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#888", marginBottom: 2 }}>
          {teamMembers[0].position.en} | {teamMembers[0].position.ar}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#666",
            fontSize: "0.95rem",
            marginBottom: 3,
            lineHeight: 1.5,
          }}
        >
          {teamMembers[0].description.en}
          <br />
          {teamMembers[0].description.ar}
        </Typography>
        {/* <Button
          variant="contained"
          sx={{
            background: '#F97316',
            color: "#fff",
            textTransform: "capitalize",
            borderRadius: "50px",
            padding: "10px 20px",
            boxShadow: "0px 8px 15px rgba(255, 105, 135, 0.4)",
            "&:hover": {
              background: "linear-gradient(90deg, #ff6f61, #ff6f61)",
            },
          }}
        >
          Learn More | اعرف أكثر
        </Button> */}
      </CardContent>
    </Card>
  </Grid>

  {/* Remaining Cards */}
  {teamMembers.slice(1).map((member, index) => (
    <Grid 
      item 
      xs={12} 
      sm={6} 
      md={4} 
      lg={3} 
      key={index + 1}
    >
      <Card
        sx={{
          background: "linear-gradient(145deg, #e6e6e6, #ffffff)",
          boxShadow: "10px 10px 30px #bebebe, -10px -10px 30px #ffffff",
          borderRadius: "20px",
          padding: 3,
          textAlign: "center",
          "&:hover": {
            transform: "scale(1.05)",
            transition: "transform 0.3s ease",
          },
        }}
      >
        <Avatar
          alt={member.name.en}
          src={member.photo}
          sx={{
            width: 120,
            height: 120,
            margin: "auto",
            border: "5px solid #ffffff",
            boxShadow: "0 8px 20px rgba(0,0,0,0.2)",
          }}
        />
        <CardContent>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              marginBottom: 1,
              background: '#F97316',
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {member.name.en} | {member.name.ar}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#888", marginBottom: 2 }}>
            {member.position.en} | {member.position.ar}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#666",
              fontSize: "0.95rem",
              marginBottom: 3,
              lineHeight: 1.5,
            }}
          >
            {member.description.en}
            <br />
            {member.description.ar}
          </Typography>
          {/* <Button
            variant="contained"
            sx={{
              background: '#F97316',
              color: "#fff",
              textTransform: "capitalize",
              borderRadius: "50px",
              padding: "10px 20px",
              boxShadow: "0px 8px 15px rgba(255, 105, 135, 0.4)",
              "&:hover": {
                background: "linear-gradient(90deg, #ff6f61, #ff6f61)",
              },
            }}
          >
            Learn More | اعرف أكثر
          </Button> */}
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>

     
    </Box>
    {/* <Page1/>
    <Page2/>
    <Page3/>
    <Page4/> */}
    </div>
  );
};

export default Teampage;
